import { Container, Card, CardContent, Typography, makeStyles, useTheme, CardMedia, Box } from "@material-ui/core"
import "@fontsource/roboto"
import React from "react"
import Header from '../components/header';

import "./index.css";
import { graphql } from "gatsby";

// @ts-ignore the file is there but, typescript can't see it
import spencer from './spencer.jpeg';
import GithubIcon from "../components/github";

const CardData = (data: any) => {
  return (
    <a key={data.id} href={data.link} className='card-link'>
      <Card style={{marginBottom: '10px', maxWidth:'800px', display:'inline-block'}}>
        <CardContent>
          <Typography component="h5" variant="h5" className='centered'>
            {data.title} 
          </Typography>
        </CardContent>
        <div className='card-body'>
          <img
            className='card-image'
            width="20%"
            height="20%"
            src={data.image}
          />
          <div>
            <CardContent>
              <Typography variant="subtitle1">
                {data.description}
              </Typography>
              {/* {
                !data.github_link ? null :
                <div style={{marginTop:'10px'}}>
                  Find the code on <GithubIcon height={'16px'} link={data.github_link}/>
                </div>
              } */}
            </CardContent>
          </div>
        </div>
      </Card>
    </a>
  )
}

const headerDetails = {
  title: 'Spencer Seeger\'s Website',
  // img: '/spencer.jpeg'
  description: 'A Website built to show off the cool projects built by Spencer Seeger.'
}

const HomePage = (data: any) => {
  return (
    <Header details={headerDetails}>
      <Box className='info-box'>
        {/* <Container>Test content</Container> */}
        <div>
          <Typography variant="h3" className='info-box-title'>
            Spencer Seeger
          </Typography>
          <div>
            <img className='info-box-image' src={spencer}/>
          </div>
          <div className='info-box-description'>
            I am a software engineer for Google. Even though I code a lot at work, I can hardly get enough of it. This site shows all of my different adventures into the wonderful world of coding. I try learning everything, from AI to Graphics!
          </div>
        </div>
      </Box>
      <Container className='cards-section'>
        <div className='cards-section-title'>
          <Typography variant='h4' >
          Recent Projects
          </Typography>
        </div>
        { data.data.allCardInfoJson.edges.map(element => {
          return CardData(element.node);
        })}
      </Container>
    </Header>
  )
}

export const query = graphql`
{
  allCardInfoJson(sort: {fields: date, order: DESC}, limit: 5, filter: {ready: {eq: true}}) {
    edges {
      node {
        date
        description
        id
        image
        tags
        title
        link
        github_link
      }
    }
  }
}
`;

export default HomePage;